import React, { useState } from 'react';
import Icon from '../../../components/Icon/Icon';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { getClients, getVisit, getVisits } from '../../../utils/api/visits';
import { useQuery } from 'react-query';
import toast from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';

function CallNavigation() {
  const { id } = useParams();
  const { data: visit } = useQuery(`visits/${id}`, () => getVisit(id));
  // const [linkCall, setlinkCall] = useState(`/call/${visit?.unité?._id}`);
  const [ShowAvatar, setShowAvatar] = useState(true)
  const linkCall=`/call/${visit?.client?._id}?unité_id=${visit?.unité}&showAvatar=${ShowAvatar}`
  console.log({ visit });
  return (
    <>
      <div className='call-card'>
        <div className='type'>
          <button>
            <input defaultChecked type='radio' name='call' value={'video'} />
            <h4>
              <Icon name='video' />
              Appel Vidéo
            </h4>
            <span>Jusqu’à 4 Participants</span>
          </button>
          <button>
            <input type='radio' name='call' value={'vocal'} />
            <h4>
              <Icon name='phone' />
              Appel Vocal
            </h4>
            <span>Jusqu’à 4 Participants</span>
          </button>
        </div>

        <div style={{ margin: 'auto 0', width: 260 }}>
          <div className='inputs-label'>
            <div className='input-container'>
              <label htmlFor=''>Client</label>

              <input disabled type='text' value={visit?.client?.nom} />
            </div>

            <div className='input-container'>
              <label htmlFor=''>Tour</label>
              <input disabled value={visit?.lienVR} />
            </div>
          </div>
        </div>

        <div style={{ margin: 'auto 0' }}>
          <h5>Paramètre de tour</h5>
          <div className='switch'>
            <input defaultChecked type='checkbox' /> <div className='in'></div>{' '}
            Invité avant l’hôte
          </div>
          <div className='switch'>
            <input defaultChecked type='checkbox' /> <div className='in'></div>{' '}
            Musique
          </div>
          <div className='switch'>
            <input value={ShowAvatar} onChange={(e)=>setShowAvatar(v=>!v)} defaultChecked type='checkbox' /> <div className='in'></div>{' '}
            Mode avatar
          </div>
        </div>

        <div className='copy'>
          <button
            onClick={() => {
              navigator.clipboard.writeText(window.location.origin + linkCall);
              toast.success('Text Copied');
            }}
          >
            <span>Lien d’hôte</span> <Icon name='copy' />
          </button>
          <button
            onClick={() => {
              navigator.clipboard.writeText(window.location.origin + linkCall);
              toast.success('Text Copied');
            }}
          >
            <span>Lien d’invité</span> <Icon name='copy' />
          </button>
        </div>

        <div className='line'></div>
        <Link className='button' to={linkCall}>
          Sauvegarder <Icon name='checkbox' />
        </Link>
      </div>
      <Swiper
        slidesPerView={1}
        modules={[Pagination, Navigation]}
        pagination={{ clickable: true }}
        navigation={true}
      >
        <SwiperSlide className='more-inf'>
          <div>
            <h2>Choisissez une configuration adaptée</h2>
            <p>
              Choisissez la configuration de conférence qui correspond à vos
              besoins - vidéo, audio ou une combinaison des deux. Activez ou
              limitez la fonctionnalité des avatars des utilisateurs selon vos
              besoins.
            </p>
          </div>
          <img src='/app.png' alt='' />
        </SwiperSlide>
        <SwiperSlide className='more-inf'>
          <div>
            <h2>Organisez-vous avec les bons outils</h2>
            <p>
              Interagir avec les bons outils est essentiel pour une réunion
              organisée et efficace. Nous vous fournissons les outils dont vous
              avez besoin pour assurer la commodité des hôtes et des
              utilisateurs, y compris le chat, le partage d'écran et l'orateur
              dominant.
            </p>
          </div>
          <img src='/app2.png' alt='' />
        </SwiperSlide>
        <SwiperSlide className='more-inf'>
          <div>
            <h2>Gestion de bout en bout de la plateforme</h2>
            <p>
              es hôtes peuvent se connecter à leur tableau de bord pour gérer
              leur emploi du temps, les appels vidéo, les chats, les prospects,
              etc. Les invités à la conférence peuvent se joindre à des appels
              vidéo spontanés ou planifier des réunions vidéo à l'aide du
              calendrier. Une gestion de bout en bout dans un espace de travail
              intuitif.
            </p>
          </div>
          <img src='/app3.png' alt='' />
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export default CallNavigation;
