import { queryClient } from '../../layouts/ReactQueryLayout';
import { Axios } from './axios';

const getVisits = () => {
  return Axios.get(`/visits`).then(({ data }) => {
    let Visits=data?.sort((a, b) => new Date(b?.dateVisite) - new Date(a?.dateVisite));

    console.log({ Visits });

    return Visits;
  });
};

const getVisit = (id) => {
  return Axios.get(`/visits/${id}`).then(({ data }) => {
    console.log({ Visit: data });
    return data;
  });
};

const getClients = () => {
  return Axios.get(`/clients`).then(({ data }) => {
    console.log({ Clients: data });
    return data;
  });
};

const createVisit = ({ data }) => {
  return Axios.post(`/visits`, data).then(({ data }) => {
    console.log({ Visit: data });
    queryClient.invalidateQueries('visits');
    return data;
  });
};


const startCall = ({ data,id }) => {
  return Axios.post(`/clients/${id}/visits`, data).then(({ data }) => {
    console.log({ Visit: data });
    queryClient.invalidateQueries('visits');
    return data;
  });
};


const endCall = ({ data,id,visit_id }) => {
  return Axios.put(`/clients/${id}/visits/${visit_id}`, data).then(({ data }) => {
    console.log({ Visit: data });
    queryClient.invalidateQueries('visits');
    return data;
  });
};



export { getVisits, getClients, createVisit, getVisit ,startCall,endCall};
