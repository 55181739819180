import React, { useState } from 'react';
import './Staging.css';
import FlexLinks from '../../components/FlexLinks/FlexLinks';
import Icon from '../../components/Icon/Icon';
import { items_3d } from '../../data';
import AddObjectForm from './AddObject';
import { useQuery } from 'react-query';
import { getAllObjects } from '../../utils/api/object3d';

function Staging() {
  const [ShowObjects, setShowObjects] = useState(false);
  const [SelectedItem, setSelectedItem] = useState(0);
  const [activeMode, setActiveMode] = useState('Position');
  const [showObjectMenu, setShowObjectMenu] = useState(false);
  const [ObjectsType, setObjectsType] = useState('Fourniture de bureau');

  const { data: objects } = useQuery('objects', getAllObjects);

  return (
    <div className='Staging'>
      <FlexLinks setShowObjectMenu={setShowObjectMenu}>
        {({
          print_3d_items,
          selected,
          Control,
          setSelected = () => {},
          nodes = [],
          setNodes = () => {},
          save_items = () => {},
        }) => (
          <>
            {selected && (
              <div className='overlay' onClick={() => setSelected()}></div>
            )}
            <div className='Objects-container'>
              <div className={`Objects ${ShowObjects ? 'active' : ''}`}>
                <div className='top' onClick={() => setShowObjects((o) => !o)}>
                  <p>Choisissez et glissez vers votre espace virtuel</p>
                  <button>
                    <Icon name='arrow-top' />
                  </button>
                </div>
                {ShowObjects && (
                  <div className='body'>
                    <ul className='nav'>
                      <li>Librairie</li>
                      <li>Source</li>
                      <button
                        onClick={() => {
                          setShowObjectMenu(
                            (showObjectMenu) => !showObjectMenu
                          );
                        }}
                      >
                        Ajouter un objet
                      </button>
                    </ul>
                    <div className='line'></div>
                    <div className='input-container'>
                      <label htmlFor=''>Groupe</label>
                      <select
                        onChange={(e) => setObjectsType(e?.target?.value)}
                      >
                        <option value='Fourniture de bureau'>
                          Fourniture de bureau
                        </option>
                        <option value='Mes Objects'>Mes Objects</option>
                      </select>
                    </div>
                    <div className='input-container'>
                      <label htmlFor=''>Rechercher</label>
                      <input type='text' placeholder='Chaises' />
                      <Icon name='search' />
                    </div>
                    <div className='input-container'>
                      <input type='text' placeholder='Rechercher' />
                    </div>
                    <div className='select-item'>
                      {[
                        'Chaise',
                        'Table',
                        'Canapé',
                        'Fauteuille',
                        'Accessoire',
                      ].map((item, i) => (
                        <button
                          key={item}
                          type='button'
                          onClick={() => {
                            setSelectedItem(i);
                            // setShowObjects(false);
                          }}
                          className={SelectedItem === i ? 'active' : ''}
                        >
                          {item}
                        </button>
                      ))}
                    </div>
                    <div className='items-3d'>
                      {(ObjectsType === 'Fourniture de bureau'
                        ? items_3d
                        : objects?.map((o) => ({
                            url: o?.objectUrl,
                            img: o?.imgUrl,
                            type: 'mp.gltfLoader',
                            scale: { x: 1, y: 1, z: 1 },
                          }))
                      )?.map((item, i) => (
                        <div
                          onClick={() => {
                            print_3d_items(item, true, i);
                            setShowObjects(false);
                          }}
                          className='item-3d'
                          key={i}
                        >
                          {item?.img && <img src={item?.img} />}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {selected && (
              <div className='Staging-options-container'>
                <div className='flex flex-col px-6  py-4 w-96 bg-white shadow-lg rounded-lg'>
                  <h2 className='text-lg font-normal mb-2'>Mode</h2>
                  <div className='flex mb-2'>
                    <div className='flex bg-gray-100 rounded-2xl overflow-hidden w-full'>
                      <button
                        className={`py-4 px-6 text-blue-500 w-1/3 ${
                          activeMode === 'Position' ? 'bg-blue-100' : ''
                        }`}
                        onClick={() => {
                          setActiveMode('Position');
                          Control.inputs.mode = 'translate';
                          Control.inputs.showX = true;
                          Control.inputs.showZ = true;
                          console.log('selected', selected);
                        }}
                      >
                        Position
                      </button>
                      <button
                        className={`py-4 px-6 text-blue-500 w-1/3 ${
                          activeMode === 'Rotation' ? 'bg-blue-100' : ''
                        }`}
                        onClick={() => {
                          setActiveMode('Rotation');

                          Control.inputs.mode = 'rotate';
                          Control.inputs.showX = false;
                          Control.inputs.showZ = false;
                          Control.inputs.showY = true;
                        }}
                      >
                        Rotation
                      </button>
                      <button
                        className={`py-4 px-6 text-blue-500 w-1/3 ${
                          activeMode === 'Échelle' ? 'bg-blue-100' : ''
                        }`}
                        onClick={() => {
                          setActiveMode('Échelle');
                          Control.inputs.mode = 'scale';
                          Control.inputs.showX = true;
                          Control.inputs.showY = true;
                          Control.inputs.showZ = true;
                        }}
                      >
                        Échelle
                      </button>
                    </div>
                  </div>
                  <h2 className='text-base font-normal mb-1'>
                    Manipulation de l’objet
                  </h2>
                  {/* 1.2 */}
                  {/* Position Inputs */}
                  {activeMode === 'Position' && (
                    <div>
                      <h3 className='text-sm'>Position</h3>
                      <div className='grid grid-cols-3 gap-2 mb-2'>
                        <div className='flex flex-col'>
                          <label className='text-sm'>X</label>
                          <input
                            type='number'
                            className='border p-2 rounded'
                            defaultValue={Number(
                              selected.obj3D.position.x
                            ).toFixed(5)}
                            onChange={(e) =>
                              selected.obj3D.position.setX(e.target.value)
                            }
                            disabled={activeMode != 'Position'}
                            step='0.1'
                          />
                        </div>
                        <div className='flex flex-col'>
                          <label className='text-sm'>Y</label>
                          <input
                            type='number'
                            className='border p-2 rounded'
                            defaultValue={Number(
                              selected.obj3D.position.y
                            ).toFixed(5)}
                            onChange={(e) =>
                              selected.obj3D.position.setY(e.target.value)
                            }
                            disabled={activeMode != 'Position'}
                            step='0.1'
                          />
                        </div>
                        <div className='flex flex-col'>
                          <label className='text-sm'>Z</label>
                          <input
                            type='number'
                            className='border p-2 rounded'
                            defaultValue={Number(
                              selected.obj3D.position.z
                            ).toFixed(5)}
                            onChange={(e) =>
                              selected.obj3D.position.setZ(e.target.value)
                            }
                            disabled={activeMode != 'Position'}
                            step='0.1'
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Rotation Inputs */}
                  {activeMode === 'Rotation' && (
                    <div>
                      <h3 className='text-sm'>Rotation</h3>
                      <div className='grid grid-cols-3 gap-2 mb-2'>
                        <div className='flex flex-col'>
                          <input
                            type='number'
                            step='0.1'
                            className='border p-2 rounded'
                            defaultValue={Number(
                              selected.obj3D.rotation.y
                            ).toFixed(5)}
                            disabled={activeMode != 'Rotation'}
                            onChange={(e) =>
                              selected.obj3D.rotation.set(
                                selected.obj3D.rotation.x,
                                e.target.value,
                                selected.obj3D.rotation.z
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Scale Input */}
                  {activeMode === 'Échelle' && (
                    <div>
                      <h3 className='text-sm'>Échelle</h3>
                      <div className='grid grid-cols-3 gap-2 mb-2'>
                        <div className='flex flex-col'>
                          <input
                            type='number'
                            step='0.1'
                            className='border p-2 rounded'
                            defaultValue={Number(
                              selected.obj3D.scale.x
                            ).toFixed(5)}
                            disabled={activeMode != 'Échelle'}
                            onChange={(e) =>
                              selected.obj3D.scale.set(
                                e.target.value,
                                e.target.value,
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Buttons */}
                  <div className='flex justify-between gap-2 w-full my-4'>
                    <button
                      onClick={() => {
                        Control.inputs.selection = null;
                        selected?.stop();
                        setNodes((n) =>
                          n.filter((item) => item.id != selected.id)
                        );
                      }}
                      className='py-2 px-4 rounded-lg ml-auto'
                    >
                      Supprimer
                    </button>

                    <button
                      onClick={() => {
                        Control.inputs.selection = null;
                        setSelected();
                      }}
                      className='bg-blue-500 text-white py-2 px-4 w-1/2 rounded-lg'
                    >
                      Enregistrer
                    </button>
                  </div>
                  <button
                    onClick={save_items}
                    className='border border-black py-2 px-4 rounded-lg mb-4 w-full'
                  >
                    Copier le lien
                  </button>
                  <button className='bg-black text-white py-2 px-4 rounded-lg w-full'>
                    Publier
                  </button>
                </div>
              </div>
            )}

            {showObjectMenu && (
              <div className='Staging-options-container'>
                <AddObjectForm setShowObjectMenu={setShowObjectMenu} />
              </div>
            )}
          </>
        )}
      </FlexLinks>
    </div>
  );
}

export default Staging;
