import { popup } from '../../components/Popup/Popup';
import { queryClient } from '../../layouts/ReactQueryLayout';
import { Axios } from './axios';

const createObject = (data) => {
  return Axios.post(`/items/66db52f084ce380839e34dd7/add`, data).then(
    ({ data }) => {
      queryClient.invalidateQueries('Objects');
      return data;
    }
  );
};

const getAllObjects = () => {
  return Axios.get(`/items/66db52f084ce380839e34dd7/`).then(({ data }) => {
    console.log({ Objects: data });
    return data;
  });
};

const getObject = (id) => {
  return Axios.get(`/items/${id}`).then(({ data }) => {
    console.log({ capture: data });
    return data;
  });
};

const updateObject = (id, data) => {
  return Axios.put(`/items/${id}`, data).then(({ data }) => {
    queryClient.invalidateQueries('Objects');
    return data;
  });
};

const deleteObject = (data, id) => {
  return Axios.put(`/items/${id}`, data).then(({ data }) => {
    queryClient.invalidateQueries('gestion');
    return data;
  });
};

export { createObject, getAllObjects, getObject, updateObject, deleteObject };
