import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Upload from '../../components/Upload/Upload';
import { createObject } from '../../utils/api/object3d';
import './AddObjectForm.css';
import Icon from '../../components/Icon/Icon';

export default function AddObjectForm({ setShowObjectMenu }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const onSubmit = (data) => {
    createObject(data)
      .then(() => {})
      .catch((err) => {});
  };

  return (
    <div className='p-4 mx-auto bg-white rounded-lg shadow-lg AddObjectForm'>
      <div className='flex justify-between items-center mb-3'>
        <h2 className='text-xl font-bold'>Ajouter un nouveau Objet</h2>

        <button
          onClick={() => {
            setShowObjectMenu(false);
          }}
          type='button'
          className='text-gray-500 hover:text-gray-700'
        >
          ✕
        </button>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className='w-full flex-col gap-2'>
        {/* Object Name Input */}
        <div className='mb-2'>
          <input
            id='name'
            type='text'
            placeholder="Nom de l'objet"
            {...register('name', {
              required: "Nom de l'objet est requis",
            })}
            className={`w-full px-4 py-2 border ${
              errors.name ? 'border-red-500' : 'border-gray-300'
            } rounded-lg focus:outline-none focus:border-blue-500`}
          />
          {errors.name && (
            <p className='text-red-500 text-sm mt-1'>{errors.name.message}</p>
          )}
        </div>

        {/* Object File Upload */}
        <div className=''>
          <div className='flex items-center relative upload-container'>
            <div className='flex items-center justify-between'>
              <span className=' text-gray-700 mb-2'>
                Télécharger l'objet <br /> 20 MB max en format GLTF
              </span>
              <label
                htmlFor='objectUrl'
                className='cursor-pointer  py-2 px-4 rounded-lg  '
              >
                <svg
                  width='36'
                  height='38'
                  viewBox='0 0 36 38'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect width='37.8672' height='37.8672' fill='#F6F6F6' />
                  <path
                    d='M26.8226 17.3558H20.5114V11.0446C20.5114 10.6261 20.3452 10.2248 20.0493 9.92892C19.7534 9.63303 19.3521 9.4668 18.9336 9.4668C18.5151 9.4668 18.1138 9.63303 17.8179 9.92892C17.522 10.2248 17.3558 10.6261 17.3558 11.0446V17.3558H11.0446C10.6261 17.3558 10.2248 17.522 9.92892 17.8179C9.63303 18.1138 9.4668 18.5151 9.4668 18.9336C9.4668 19.3521 9.63303 19.7534 9.92892 20.0493C10.2248 20.3452 10.6261 20.5114 11.0446 20.5114H17.3558V26.8226C17.3558 27.241 17.522 27.6424 17.8179 27.9383C18.1138 28.2342 18.5151 28.4004 18.9336 28.4004C19.3521 28.4004 19.7534 28.2342 20.0493 27.9383C20.3452 27.6424 20.5114 27.241 20.5114 26.8226V20.5114H26.8226C27.241 20.5114 27.6424 20.3452 27.9383 20.0493C28.2342 19.7534 28.4004 19.3521 28.4004 18.9336C28.4004 18.5151 28.2342 18.1138 27.9383 17.8179C27.6424 17.522 27.241 17.3558 26.8226 17.3558Z'
                    fill='#A7A7A7'
                  />
                </svg>
              </label>
              {watch('objectUrl') && <p>{watch('objectUrl')}</p>}
            </div>
            <Upload
              {...{
                isImage: false,
                onUpload: (url) => setValue('objectUrl', url),
                hiden: true,
              }}
            />
          </div>
        </div>

        {/* Icon File Upload */}
        <div className=''>
          {/* <div className="flex items-center relative upload-container"> */}
          <div
            style={{ display: 'flex' }}
            className=' items-center justify-between relative'
          >
            <span className=' text-gray-700 mb-2'>
              Télécharger l'objet icon <br /> 10 MB max en format PNG
            </span>
            <label
              htmlFor='imgUrl'
              className='cursor-pointer py-2 px-4 rounded-lg '
            >
              <svg
                width='36'
                height='38'
                viewBox='0 0 36 38'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <rect width='37.8672' height='37.8672' fill='#F6F6F6' />
                <path
                  d='M26.8226 17.3558H20.5114V11.0446C20.5114 10.6261 20.3452 10.2248 20.0493 9.92892C19.7534 9.63303 19.3521 9.4668 18.9336 9.4668C18.5151 9.4668 18.1138 9.63303 17.8179 9.92892C17.522 10.2248 17.3558 10.6261 17.3558 11.0446V17.3558H11.0446C10.6261 17.3558 10.2248 17.522 9.92892 17.8179C9.63303 18.1138 9.4668 18.5151 9.4668 18.9336C9.4668 19.3521 9.63303 19.7534 9.92892 20.0493C10.2248 20.3452 10.6261 20.5114 11.0446 20.5114H17.3558V26.8226C17.3558 27.241 17.522 27.6424 17.8179 27.9383C18.1138 28.2342 18.5151 28.4004 18.9336 28.4004C19.3521 28.4004 19.7534 28.2342 20.0493 27.9383C20.3452 27.6424 20.5114 27.241 20.5114 26.8226V20.5114H26.8226C27.241 20.5114 27.6424 20.3452 27.9383 20.0493C28.2342 19.7534 28.4004 19.3521 28.4004 18.9336C28.4004 18.5151 28.2342 18.1138 27.9383 17.8179C27.6424 17.522 27.241 17.3558 26.8226 17.3558Z'
                  fill='#A7A7A7'
                />
              </svg>
            </label>
            <Upload
              {...{
                isImage: true,
                onUpload: (url) => setValue('imgUrl', url),

                hiden: true,
              }}
            />
          </div>
        </div>
        {watch('imgUrl') && (
          <div className='img-src'>
            <button onClick={() => setValue('imgUrl', '')}>
              <Icon name='close' />
            </button>
            <img src={watch('imgUrl')} />
          </div>
        )}
        {/* </div> */}

        {/* Category Selection */}
        <div className=''>
          <label className='block text-gray-700 mb-2' htmlFor='category'>
            Catégorie
          </label>
          <select
            id='category'
            {...register('category', { required: 'La catégorie est requise' })}
            className='w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500'
          >
            <option value=''>Sélectionner une catégorie</option>
            <option value='Salle à Manger'>Salle à Manger</option>
            <option value='Salon'>Salon</option>
            <option value='Chambre'>Chambre</option>
          </select>
          {errors.category && (
            <p className='text-red-500 text-sm mt-1'>
              {errors.category.message}
            </p>
          )}
        </div>

        {/* Buttons */}
        <div
          style={{ display: 'flex' }}
          className=' gap-2 items-center justify-center mt-2'
        >
          <button
            onClick={() => {
              setShowObjectMenu(false);
            }}
            type='button'
            style={{ minWidth: '120px', flexWrap: 'nowrap' }}
            className='py-2 px-4  h-[34px] border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-100 flex items-center justify-center'
          >
            Annuler
          </button>
          <button
            style={{ minWidth: '120px', flexWrap: 'nowrap' }}
            type='submit'
            className='py-2 px-4  h-[34px] bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center'
          >
            Ajouter
          </button>
        </div>
      </form>
    </div>
  );
}
