import FlexLinks from "../../components/FlexLinks/FlexLinks";
import "./EtatDesLieux.css";

function EtatDesLieux() {
  return (
    <div className="EtatDesLieux">
      <FlexLinks>{() => {}}</FlexLinks>
    </div>
  );
}

export default EtatDesLieux;
