export const DATA_Prop = [
  {
    image:
      'https://s3-alpha-sig.figma.com/img/9089/ad83/dad01bf88dc5d00fc8299a91bfc33302?Expires=1713744000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=OUBMWvEHU~cT0AE2EG~-C8XDqA5FmgVbrUWlpCtLOxYDiFe3xDyti3FuMErLMzv~psRhY62Cjay-18zEc5SJCI-RkT8z3xlCVqM-n6-R5dFsGYUZgwvoSXdCQEgxKgWd2qpzAEJh~M177ilQaqPHLMLjvANa7dWPJogOJnZheiIhhNFGkFdX0qhi1UnSpDy~qYF7QDODrexCjyp7DBzgvm~8~pogwMQv-vcc2j10~pKRibSIM154VcNEaNX86ivQVa3vje8xBOHk3~2FnqTPsDVGMRDbEeJzUiqAPH1aSQKkn1~x9HRszLTSrk-tLc5QHhr20oh~XCkMRiybIQSn8g__',
    prix: '9900',
    nom: 'Villa Janna',
    disponible: 'Disponible',
    address: '2699 Hay Targa, Marrakech, MA',
    lits: 3,
    bathrooms: 2,
    space: 250,
  },
  {
    image:
      'https://s3-alpha-sig.figma.com/img/9089/ad83/dad01bf88dc5d00fc8299a91bfc33302?Expires=1713744000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=OUBMWvEHU~cT0AE2EG~-C8XDqA5FmgVbrUWlpCtLOxYDiFe3xDyti3FuMErLMzv~psRhY62Cjay-18zEc5SJCI-RkT8z3xlCVqM-n6-R5dFsGYUZgwvoSXdCQEgxKgWd2qpzAEJh~M177ilQaqPHLMLjvANa7dWPJogOJnZheiIhhNFGkFdX0qhi1UnSpDy~qYF7QDODrexCjyp7DBzgvm~8~pogwMQv-vcc2j10~pKRibSIM154VcNEaNX86ivQVa3vje8xBOHk3~2FnqTPsDVGMRDbEeJzUiqAPH1aSQKkn1~x9HRszLTSrk-tLc5QHhr20oh~XCkMRiybIQSn8g__',
    prix: '9900',
    nom: 'Villa Janna',
    disponible: 'Disponible',
    address: '2699 Hay Targa, Marrakech, MA',
    lits: 3,
    bathrooms: 2,
    space: 250,
  },
  {
    image:
      'https://s3-alpha-sig.figma.com/img/ac63/b465/af52dd4b692d61ad7a4baab0c20f9828?Expires=1713744000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=qFjFnFp8PXjWeo52HfwiXYiuvtzM~RZXmCMUIzn8YKqyy5-FGAvp4DsfAQ3ArTgS~E~qob-tcT30CYm~2Txj0p7~UvFGbrnFusqpFHFwYvxtC1c1Fi~IVdDarwC72FDsDvOkV4uxE6yabohi8wqqyyfljzPL25QnWVeNMHx89uIpQUh8kcvfDdZXhTGKE-mHaDlNRX9CSpOvjdqO7HKbyuFDKTDJjJH92bmDmJLVnTWw1LzBCOzcp7n8Ybms8JdqkVFE58TwT6IibforqeQfqna2wLt3MX70yxpVGaNuFRSYas~3I9aN9oTdFbyQyQbCuZ7RldT1w02VLBQvTEi6Tg__',
    prix: '9900',
    nom: 'Villa Janna',
    disponible: 'Disponible',
    address: '2699 Hay Targa, Marrakech, MA',
    lits: 3,
    bathrooms: 2,
    space: 250,
  },
  {
    image:
      'https://s3-alpha-sig.figma.com/img/3d02/73e9/8fccd02c9c8d43afedc12b395e8721fe?Expires=1713744000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ZYnI8u9xHcxfDN2zvWTDnS0YcOCkDBHnRWLxn5PXz2-dY64SF8qqE2EliI98lMiWKb3SffAUJekZ0mHbSufceGtuo8ToAjM2eEezgVO4RPZxcbBAD3tyaj~50OELQ9mOGWCxWSEzx0-0Il0SndhxN2bK0Lbozv-IdbxdIsYPLbj~QpOaVAIMnAzLJscvdueIycO96JxM~ENkBrY-8zuPmQFBqORDDmGpRUB9IBIEaW~4CjLMOhaMZMlBwQw5CcaN4T6cjaMwic4f~n1xDMJ7fFxOmyFwPUHEVKwXfWSHQw8pW479JPzl8RnQ3wtT-01TdgQolKsNeCwU3v3T6ihRrg__',
    prix: '9900',
    nom: 'Villa Janna',
    disponible: 'Disponible',
    address: '2699 Hay Targa, Marrakech, MA',
    lits: 3,
    bathrooms: 2,
    space: 250,
  },
  {
    image:
      'https://s3-alpha-sig.figma.com/img/f085/a66e/ff6976f015d040dd8d8cd44b3cc0ad9c?Expires=1713744000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=jvYcpKg41MAKqnbH5zILYtb4gV4K3uYvj9HlcQ6mmlfnLOjKCRzCPm8RP3UGbRlXwZUnJV07awfKYXJY-a~bmxa82Is0RyoqjuPkH~C2DMi0UWVLJjZVgG~hK4W3~k0tZCeKe1E-4PyzJ-Z~xADvA1G66lsUgo0kSvvZwCWn2ysXtm4hgEjq7lsLvTndXr~OcCFv5PXt3cLZPIDjmXAHOSKHGPNOLSpli~Dw1BTiDt6vWDmtyq35UFA9DZqvtrvuGT3s01iJ3VkatEKy7xnsrjokhuK~dYk9TbzLQKS2VMKJQ29Hlj8L1Kl0rsLeikKX~lPDvvHNhgDdhoa8g7nDzg__',
    prix: '9900',
    nom: 'Villa Janna',
    disponible: 'Disponible',
    address: '2699 Hay Targa, Marrakech, MA',
    lits: 3,
    bathrooms: 2,
    space: 250,
  },
  {
    image:
      'https://s3-alpha-sig.figma.com/img/b766/3fa9/b3d1d8d5b8800aed0fa978e779a11f9a?Expires=1713744000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Bt835O-~8j2BTYckZrhJ7S~sk68kCIbILhOQ2uNM8S-NMRUlxE9Jzb1SJcsUKruDBSteQ2OSanjo7GNF7FeK5DhIL6TB4yz5r2G2QsPThYxubHJ~fA5ACq6FkK-SQYt-37zFb~eHpZfIsEHv68zvjBlnLA8MKZTEHME34vQ2GUhYndQx8fUbeJr2hmZ5D~HBRYdWiBLY7~jDBTvh5nxo9Qsh5Mxzs52xAyA~kNM~TTkTzqgFdzsXp4T272rl1~5sLVl95DnG9f6Zoe7Cj68hLW6HJ66qTJMK0dPDGP7hF-3nPcI0n5OYY4npjprcpCukzhm~RSJdYW5Hz1ItqQWJLQ__',
    prix: '9900',
    nom: 'Villa Janna',
    disponible: 'Disponible',
    address: '2699 Hay Targa, Marrakech, MA',
    lits: 3,
    bathrooms: 2,
    space: 250,
  },
  {
    image:
      'https://s3-alpha-sig.figma.com/img/5f7a/f02f/8ce09b54f88bdd5341c121e0c80d48ec?Expires=1713744000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=hAA7jZhZGEksAFT95EnaZisCLg27ayy28WkNHYeBOgO-UzgFFFk2LLxJdFvaiFKtc8FGlV5g3DWiFBJ1p-lhsa-rpacIMpwy~t33IHgZDLcqQVWXNFa2o9DlhhVEwBtGwyYIA-by4aLNUcFohaKraniO5zPUY~H2JtM0kPD7n~CPWzA6UkS-AX74Iy3jQMNybYNVzCfpZRa0AYyS4mSGMML8I4Hbd8LXZfmlWaXgQ82qFmJZahL4Im1i4wioyl7Jq8gFAmIm6TgRghHj6Dpn89JxvBOCbLbB3SJgEfMLgA3D-lcYMqD~3lG6PvrhJeyI4VFkfqISPV-9bW7OURNgfQ__',
    prix: '9900',
    nom: 'Villa Janna',
    disponible: 'Disponible',
    address: '2699 Hay Targa, Marrakech, MA',
    lits: 3,
    bathrooms: 2,
    space: 250,
  },
  {
    image:
      'https://s3-alpha-sig.figma.com/img/9e64/bfdf/386de29f8ed7cdf52844f726d09b52cf?Expires=1713744000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=eFiICNrvZfbupQ1RMAlI78Gq5Wf-2FV0~WDQ2~b~1d9e95WTR1RkNWUqR23JdpmbIJfOR~Iw48LxhM-4-oGk4yWwOgvQNGpec~Bvx74o2jhq171QEZAfqf5GpX57uARDP5GlTwI-xr9KtINoZfJiLPscQ2YTwo7S1ozZFkt7W~7Y5t-ahTJ5GLvHl7IQFfHLWV5t79FuBsRbGR3ShInAihqzj-gmJHnoxVk54Bf9IRVL39LqBxyKAirqGDlsAN1H697ECCBKKpBe-UYZbiScDiFsVzpRzk8irNhcekCU7diCOnriFlOw0RM7MYeCv9UPvmzPlzer5NN8l8xecDvBRw__',
    prix: '9900',
    nom: 'Villa Janna',
    disponible: 'Disponible',
    address: '2699 Hay Targa, Marrakech, MA',
    lits: 3,
    bathrooms: 2,
    space: 250,
  },
  {
    image:
      'https://s3-alpha-sig.figma.com/img/76c3/80ba/0496562fbd58732f510f9eca36dc4527?Expires=1713744000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gEddzFky7X23krDkF~LPm1CKvh4DEjgIlacF~sY2cmBphvvh52E~woq7skiSCLw7vzQJer41NvpZ~mcKAIJE8hJOdCmj8Yr6BoBe0L688whwp6UEP7vzHXTsIu9utAkCUnrOn951IDPP~S4ZBPRpFwMpflUsOjwxnkLlkAX-ANZc8SM6Mrtu0i1-MFYBp5FtOf5nzj~AH8nGt51U21AphtFeQOC-gqD1LJXmwpDpEv5ApNPC2rfMXdMtS13ylGk4EOpCR4fAxJuUSUSlL7XsVeys-S-udamRspOuepU2pSqfc0fOJOT~b-Fvy3WR-sdgUZXh7jv4KfiY7WJJxOQxFw__',
    prix: '9900',
    nom: 'Villa Janna',
    disponible: 'Disponible',
    address: '2699 Hay Targa, Marrakech, MA',
    lits: 3,
    bathrooms: 2,
    space: 250,
  },
  {
    image:
      'https://s3-alpha-sig.figma.com/img/ab7d/e163/62e778a419cb0820b18a1880c79b968e?Expires=1713744000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bqJRG78gYWJbuScK56QfjTcjozrDbUL432ihlKwnGxS8bWPLVt1hNW5kXbtikiIX6W2VlhufYVkWsSW54c1RlWprhq~YrOY6MVFOLu-X5MnFU7QdwCk2ID8Nu2nutBYu4MvEzMkshe~pUEbbcXgbLG5xRj5VDQ0DssdiGbjO3EviP4qcdmJMD9QVyRilX3Z1fPd6sJogjJ323gqCWhVoy7B0~wVIFDPMDrbRW6N0sJXUQa4BycJN2UwqZu77HMyhSAYAL0hWuEqluNUDTe1lYKYhae6AFhweFcUuCDJl33iSjoVIbWcHa8n6D7s6a-hNFIC~aZnX1ANA099kzwGy3A__',
    prix: '9900',
    nom: 'Villa Janna',
    disponible: 'Disponible',
    address: '2699 Hay Targa, Marrakech, MA',
    lits: 3,
    bathrooms: 2,
    space: 250,
  },
  {
    image:
      'https://s3-alpha-sig.figma.com/img/ab7d/e163/62e778a419cb0820b18a1880c79b968e?Expires=1713744000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bqJRG78gYWJbuScK56QfjTcjozrDbUL432ihlKwnGxS8bWPLVt1hNW5kXbtikiIX6W2VlhufYVkWsSW54c1RlWprhq~YrOY6MVFOLu-X5MnFU7QdwCk2ID8Nu2nutBYu4MvEzMkshe~pUEbbcXgbLG5xRj5VDQ0DssdiGbjO3EviP4qcdmJMD9QVyRilX3Z1fPd6sJogjJ323gqCWhVoy7B0~wVIFDPMDrbRW6N0sJXUQa4BycJN2UwqZu77HMyhSAYAL0hWuEqluNUDTe1lYKYhae6AFhweFcUuCDJl33iSjoVIbWcHa8n6D7s6a-hNFIC~aZnX1ANA099kzwGy3A__',
    prix: '9900',
    nom: 'Villa Janna',
    disponible: 'Disponible',
    address: '2699 Hay Targa, Marrakech, MA',
    lits: 3,
    bathrooms: 2,
    space: 250,
  },
  {
    image:
      'https://s3-alpha-sig.figma.com/img/ab7d/e163/62e778a419cb0820b18a1880c79b968e?Expires=1713744000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bqJRG78gYWJbuScK56QfjTcjozrDbUL432ihlKwnGxS8bWPLVt1hNW5kXbtikiIX6W2VlhufYVkWsSW54c1RlWprhq~YrOY6MVFOLu-X5MnFU7QdwCk2ID8Nu2nutBYu4MvEzMkshe~pUEbbcXgbLG5xRj5VDQ0DssdiGbjO3EviP4qcdmJMD9QVyRilX3Z1fPd6sJogjJ323gqCWhVoy7B0~wVIFDPMDrbRW6N0sJXUQa4BycJN2UwqZu77HMyhSAYAL0hWuEqluNUDTe1lYKYhae6AFhweFcUuCDJl33iSjoVIbWcHa8n6D7s6a-hNFIC~aZnX1ANA099kzwGy3A__',
    prix: '9900',
    nom: 'Villa Janna',
    disponible: 'Disponible',
    address: '2699 Hay Targa, Marrakech, MA',
    lits: 3,
    bathrooms: 2,
    space: 250,
  },
  {
    image:
      'https://s3-alpha-sig.figma.com/img/ab7d/e163/62e778a419cb0820b18a1880c79b968e?Expires=1713744000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bqJRG78gYWJbuScK56QfjTcjozrDbUL432ihlKwnGxS8bWPLVt1hNW5kXbtikiIX6W2VlhufYVkWsSW54c1RlWprhq~YrOY6MVFOLu-X5MnFU7QdwCk2ID8Nu2nutBYu4MvEzMkshe~pUEbbcXgbLG5xRj5VDQ0DssdiGbjO3EviP4qcdmJMD9QVyRilX3Z1fPd6sJogjJ323gqCWhVoy7B0~wVIFDPMDrbRW6N0sJXUQa4BycJN2UwqZu77HMyhSAYAL0hWuEqluNUDTe1lYKYhae6AFhweFcUuCDJl33iSjoVIbWcHa8n6D7s6a-hNFIC~aZnX1ANA099kzwGy3A__',
    prix: '9900',
    nom: 'Villa Janna',
    disponible: 'Disponible',
    address: '2699 Hay Targa, Marrakech, MA',
    lits: 3,
    bathrooms: 2,
    space: 250,
  },
  {
    image:
      'https://s3-alpha-sig.figma.com/img/ab7d/e163/62e778a419cb0820b18a1880c79b968e?Expires=1713744000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bqJRG78gYWJbuScK56QfjTcjozrDbUL432ihlKwnGxS8bWPLVt1hNW5kXbtikiIX6W2VlhufYVkWsSW54c1RlWprhq~YrOY6MVFOLu-X5MnFU7QdwCk2ID8Nu2nutBYu4MvEzMkshe~pUEbbcXgbLG5xRj5VDQ0DssdiGbjO3EviP4qcdmJMD9QVyRilX3Z1fPd6sJogjJ323gqCWhVoy7B0~wVIFDPMDrbRW6N0sJXUQa4BycJN2UwqZu77HMyhSAYAL0hWuEqluNUDTe1lYKYhae6AFhweFcUuCDJl33iSjoVIbWcHa8n6D7s6a-hNFIC~aZnX1ANA099kzwGy3A__',
    prix: '9900',
    nom: 'Villa Janna',
    disponible: 'Disponible',
    address: '2699 Hay Targa, Marrakech, MA',
    lits: 3,
    bathrooms: 2,
    space: 250,
  },
  {
    image:
      'https://s3-alpha-sig.figma.com/img/ab7d/e163/62e778a419cb0820b18a1880c79b968e?Expires=1713744000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bqJRG78gYWJbuScK56QfjTcjozrDbUL432ihlKwnGxS8bWPLVt1hNW5kXbtikiIX6W2VlhufYVkWsSW54c1RlWprhq~YrOY6MVFOLu-X5MnFU7QdwCk2ID8Nu2nutBYu4MvEzMkshe~pUEbbcXgbLG5xRj5VDQ0DssdiGbjO3EviP4qcdmJMD9QVyRilX3Z1fPd6sJogjJ323gqCWhVoy7B0~wVIFDPMDrbRW6N0sJXUQa4BycJN2UwqZu77HMyhSAYAL0hWuEqluNUDTe1lYKYhae6AFhweFcUuCDJl33iSjoVIbWcHa8n6D7s6a-hNFIC~aZnX1ANA099kzwGy3A__',
    prix: '9900',
    nom: 'Villa Janna',
    disponible: 'Disponible',
    address: '2699 Hay Targa, Marrakech, MA',
    lits: 3,
    bathrooms: 2,
    space: 250,
  },
  {
    image:
      'https://s3-alpha-sig.figma.com/img/ab7d/e163/62e778a419cb0820b18a1880c79b968e?Expires=1713744000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bqJRG78gYWJbuScK56QfjTcjozrDbUL432ihlKwnGxS8bWPLVt1hNW5kXbtikiIX6W2VlhufYVkWsSW54c1RlWprhq~YrOY6MVFOLu-X5MnFU7QdwCk2ID8Nu2nutBYu4MvEzMkshe~pUEbbcXgbLG5xRj5VDQ0DssdiGbjO3EviP4qcdmJMD9QVyRilX3Z1fPd6sJogjJ323gqCWhVoy7B0~wVIFDPMDrbRW6N0sJXUQa4BycJN2UwqZu77HMyhSAYAL0hWuEqluNUDTe1lYKYhae6AFhweFcUuCDJl33iSjoVIbWcHa8n6D7s6a-hNFIC~aZnX1ANA099kzwGy3A__',
    prix: '9900',
    nom: 'Villa Janna',
    disponible: 'Disponible',
    address: '2699 Hay Targa, Marrakech, MA',
    lits: 3,
    bathrooms: 2,
    space: 250,
  },
  {
    image:
      'https://s3-alpha-sig.figma.com/img/ab7d/e163/62e778a419cb0820b18a1880c79b968e?Expires=1713744000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bqJRG78gYWJbuScK56QfjTcjozrDbUL432ihlKwnGxS8bWPLVt1hNW5kXbtikiIX6W2VlhufYVkWsSW54c1RlWprhq~YrOY6MVFOLu-X5MnFU7QdwCk2ID8Nu2nutBYu4MvEzMkshe~pUEbbcXgbLG5xRj5VDQ0DssdiGbjO3EviP4qcdmJMD9QVyRilX3Z1fPd6sJogjJ323gqCWhVoy7B0~wVIFDPMDrbRW6N0sJXUQa4BycJN2UwqZu77HMyhSAYAL0hWuEqluNUDTe1lYKYhae6AFhweFcUuCDJl33iSjoVIbWcHa8n6D7s6a-hNFIC~aZnX1ANA099kzwGy3A__',
    prix: '9900',
    nom: 'Villa Janna',
    disponible: 'Disponible',
    address: '2699 Hay Targa, Marrakech, MA',
    lits: 3,
    bathrooms: 2,
    space: 250,
  },
  {
    image:
      'https://s3-alpha-sig.figma.com/img/ab7d/e163/62e778a419cb0820b18a1880c79b968e?Expires=1713744000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bqJRG78gYWJbuScK56QfjTcjozrDbUL432ihlKwnGxS8bWPLVt1hNW5kXbtikiIX6W2VlhufYVkWsSW54c1RlWprhq~YrOY6MVFOLu-X5MnFU7QdwCk2ID8Nu2nutBYu4MvEzMkshe~pUEbbcXgbLG5xRj5VDQ0DssdiGbjO3EviP4qcdmJMD9QVyRilX3Z1fPd6sJogjJ323gqCWhVoy7B0~wVIFDPMDrbRW6N0sJXUQa4BycJN2UwqZu77HMyhSAYAL0hWuEqluNUDTe1lYKYhae6AFhweFcUuCDJl33iSjoVIbWcHa8n6D7s6a-hNFIC~aZnX1ANA099kzwGy3A__',
    prix: '9900',
    nom: 'Villa Janna',
    disponible: 'Disponible',
    address: '2699 Hay Targa, Marrakech, MA',
    lits: 3,
    bathrooms: 2,
    space: 250,
  },
  {
    image:
      'https://s3-alpha-sig.figma.com/img/ab7d/e163/62e778a419cb0820b18a1880c79b968e?Expires=1713744000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bqJRG78gYWJbuScK56QfjTcjozrDbUL432ihlKwnGxS8bWPLVt1hNW5kXbtikiIX6W2VlhufYVkWsSW54c1RlWprhq~YrOY6MVFOLu-X5MnFU7QdwCk2ID8Nu2nutBYu4MvEzMkshe~pUEbbcXgbLG5xRj5VDQ0DssdiGbjO3EviP4qcdmJMD9QVyRilX3Z1fPd6sJogjJ323gqCWhVoy7B0~wVIFDPMDrbRW6N0sJXUQa4BycJN2UwqZu77HMyhSAYAL0hWuEqluNUDTe1lYKYhae6AFhweFcUuCDJl33iSjoVIbWcHa8n6D7s6a-hNFIC~aZnX1ANA099kzwGy3A__',
    prix: '9900',
    nom: 'Villa Janna',
    disponible: 'Disponible',
    address: '2699 Hay Targa, Marrakech, MA',
    lits: 3,
    bathrooms: 2,
    space: 250,
  },
  {
    image:
      'https://s3-alpha-sig.figma.com/img/ab7d/e163/62e778a419cb0820b18a1880c79b968e?Expires=1713744000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bqJRG78gYWJbuScK56QfjTcjozrDbUL432ihlKwnGxS8bWPLVt1hNW5kXbtikiIX6W2VlhufYVkWsSW54c1RlWprhq~YrOY6MVFOLu-X5MnFU7QdwCk2ID8Nu2nutBYu4MvEzMkshe~pUEbbcXgbLG5xRj5VDQ0DssdiGbjO3EviP4qcdmJMD9QVyRilX3Z1fPd6sJogjJ323gqCWhVoy7B0~wVIFDPMDrbRW6N0sJXUQa4BycJN2UwqZu77HMyhSAYAL0hWuEqluNUDTe1lYKYhae6AFhweFcUuCDJl33iSjoVIbWcHa8n6D7s6a-hNFIC~aZnX1ANA099kzwGy3A__',
    prix: '9900',
    nom: 'Villa Janna',
    disponible: 'Disponible',
    address: '2699 Hay Targa, Marrakech, MA',
    lits: 3,
    bathrooms: 2,
    space: 250,
  },
  {
    image:
      'https://s3-alpha-sig.figma.com/img/ab7d/e163/62e778a419cb0820b18a1880c79b968e?Expires=1713744000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bqJRG78gYWJbuScK56QfjTcjozrDbUL432ihlKwnGxS8bWPLVt1hNW5kXbtikiIX6W2VlhufYVkWsSW54c1RlWprhq~YrOY6MVFOLu-X5MnFU7QdwCk2ID8Nu2nutBYu4MvEzMkshe~pUEbbcXgbLG5xRj5VDQ0DssdiGbjO3EviP4qcdmJMD9QVyRilX3Z1fPd6sJogjJ323gqCWhVoy7B0~wVIFDPMDrbRW6N0sJXUQa4BycJN2UwqZu77HMyhSAYAL0hWuEqluNUDTe1lYKYhae6AFhweFcUuCDJl33iSjoVIbWcHa8n6D7s6a-hNFIC~aZnX1ANA099kzwGy3A__',
    prix: '9900',
    nom: 'Villa Janna',
    disponible: 'Disponible',
    address: '2699 Hay Targa, Marrakech, MA',
    lits: 3,
    bathrooms: 2,
    space: 250,
  },
  {
    image:
      'https://s3-alpha-sig.figma.com/img/ab7d/e163/62e778a419cb0820b18a1880c79b968e?Expires=1713744000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bqJRG78gYWJbuScK56QfjTcjozrDbUL432ihlKwnGxS8bWPLVt1hNW5kXbtikiIX6W2VlhufYVkWsSW54c1RlWprhq~YrOY6MVFOLu-X5MnFU7QdwCk2ID8Nu2nutBYu4MvEzMkshe~pUEbbcXgbLG5xRj5VDQ0DssdiGbjO3EviP4qcdmJMD9QVyRilX3Z1fPd6sJogjJ323gqCWhVoy7B0~wVIFDPMDrbRW6N0sJXUQa4BycJN2UwqZu77HMyhSAYAL0hWuEqluNUDTe1lYKYhae6AFhweFcUuCDJl33iSjoVIbWcHa8n6D7s6a-hNFIC~aZnX1ANA099kzwGy3A__',
    prix: '9900',
    nom: 'Villa Janna',
    disponible: 'Disponible',
    address: '2699 Hay Targa, Marrakech, MA',
    lits: 3,
    bathrooms: 2,
    space: 250,
  },
  {
    image:
      'https://s3-alpha-sig.figma.com/img/ab7d/e163/62e778a419cb0820b18a1880c79b968e?Expires=1713744000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bqJRG78gYWJbuScK56QfjTcjozrDbUL432ihlKwnGxS8bWPLVt1hNW5kXbtikiIX6W2VlhufYVkWsSW54c1RlWprhq~YrOY6MVFOLu-X5MnFU7QdwCk2ID8Nu2nutBYu4MvEzMkshe~pUEbbcXgbLG5xRj5VDQ0DssdiGbjO3EviP4qcdmJMD9QVyRilX3Z1fPd6sJogjJ323gqCWhVoy7B0~wVIFDPMDrbRW6N0sJXUQa4BycJN2UwqZu77HMyhSAYAL0hWuEqluNUDTe1lYKYhae6AFhweFcUuCDJl33iSjoVIbWcHa8n6D7s6a-hNFIC~aZnX1ANA099kzwGy3A__',
    prix: '9900',
    nom: 'Villa Janna',
    disponible: 'Disponible',
    address: '2699 Hay Targa, Marrakech, MA',
    lits: 3,
    bathrooms: 2,
    space: 250,
  },
  {
    image:
      'https://s3-alpha-sig.figma.com/img/ab7d/e163/62e778a419cb0820b18a1880c79b968e?Expires=1713744000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bqJRG78gYWJbuScK56QfjTcjozrDbUL432ihlKwnGxS8bWPLVt1hNW5kXbtikiIX6W2VlhufYVkWsSW54c1RlWprhq~YrOY6MVFOLu-X5MnFU7QdwCk2ID8Nu2nutBYu4MvEzMkshe~pUEbbcXgbLG5xRj5VDQ0DssdiGbjO3EviP4qcdmJMD9QVyRilX3Z1fPd6sJogjJ323gqCWhVoy7B0~wVIFDPMDrbRW6N0sJXUQa4BycJN2UwqZu77HMyhSAYAL0hWuEqluNUDTe1lYKYhae6AFhweFcUuCDJl33iSjoVIbWcHa8n6D7s6a-hNFIC~aZnX1ANA099kzwGy3A__',
    prix: '9900',
    nom: 'Villa Janna',
    disponible: 'Disponible',
    address: '2699 Hay Targa, Marrakech, MA',
    lits: 3,
    bathrooms: 2,
    space: 250,
  },
  {
    image:
      'https://s3-alpha-sig.figma.com/img/ab7d/e163/62e778a419cb0820b18a1880c79b968e?Expires=1713744000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bqJRG78gYWJbuScK56QfjTcjozrDbUL432ihlKwnGxS8bWPLVt1hNW5kXbtikiIX6W2VlhufYVkWsSW54c1RlWprhq~YrOY6MVFOLu-X5MnFU7QdwCk2ID8Nu2nutBYu4MvEzMkshe~pUEbbcXgbLG5xRj5VDQ0DssdiGbjO3EviP4qcdmJMD9QVyRilX3Z1fPd6sJogjJ323gqCWhVoy7B0~wVIFDPMDrbRW6N0sJXUQa4BycJN2UwqZu77HMyhSAYAL0hWuEqluNUDTe1lYKYhae6AFhweFcUuCDJl33iSjoVIbWcHa8n6D7s6a-hNFIC~aZnX1ANA099kzwGy3A__',
    prix: '9900',
    nom: 'Villa Janna',
    disponible: 'Disponible',
    address: '2699 Hay Targa, Marrakech, MA',
    lits: 3,
    bathrooms: 2,
    space: 250,
  },
];

export const DATA_Files = [
  {
    name: 'Contrats',
  },
  {
    name: 'Quittances de loyer',
  },
  {
    name: 'Fiches d’interventions',
  },
  {
    name: 'Documets utilisateurs',
  },
];

export const Vector3 = (x, y, z) => ({ x, y, z });

export const items_3d = [
  {
    url: '/3d/sofa_chair/scene.gltf',
    img: '/3d/sofa_chair/img.png',
    type: 'mp.gltfLoader',
    scale: Vector3(0.5, 0.5, 0.5),
  },
  {
    url: '/3d/rachid/2 seater sofa.gltf',
    img: '/3d/rachid/2 seater sofa.png',
    type: 'mp.gltfLoader',
    scale: Vector3(0.5, 0.5, 0.5),
  },
  {
    url: '/3d/rachid/bed.gltf',
    img: '/3d/rachid/bed.png',
    type: 'mp.gltfLoader',
    scale: Vector3(0.5, 0.5, 0.5),
  },
  {
    url: '/3d/rachid/Bleu velvet sofa.glb',
    img: '/3d/rachid/Bleu velvet sofapng',
    type: 'mp.gltfLoader',
    scale: Vector3(0.5, 0.5, 0.5),
  },
  {
    url: '/3d/rachid/dining set.glb',
    img: '/3d/rachid/dining setpng',
    type: 'mp.gltfLoader',
    scale: Vector3(0.5, 0.5, 0.5),
  },
  {
    url: '/3d/rachid/dining Table with 8 set.gltf',
    img: '/3d/rachid/dining Table with 8 set.png',
    type: 'mp.gltfLoader',
    scale: Vector3(0.5, 0.5, 0.5),
  },
  {
    url: '/3d/rachid/dining Tablee.glb',
    img: '/3d/rachid/dining Tableepng',
    type: 'mp.gltfLoader',
    scale: Vector3(0.5, 0.5, 0.5),
  },
  {
    url: '/3d/rachid/dining Tablw.gltf',
    img: '/3d/rachid/dining Tablw.png',
    type: 'mp.gltfLoader',
    scale: Vector3(0.5, 0.5, 0.5),
  },
  {
    url: '/3d/rachid/F.gltf',
    img: '/3d/rachid/F.png',
    type: 'mp.gltfLoader',
    scale: Vector3(0.5, 0.5, 0.5),
  },
  {
    url: '/3d/rachid/For LR.glb',
    img: '/3d/rachid/For LRpng',
    type: 'mp.gltfLoader',
    scale: Vector3(0.5, 0.5, 0.5),
  },
  {
    url: '/3d/rachid/Kirill sannikov.gltf',
    img: '/3d/rachid/Kirill sannikov.png',
    type: 'mp.gltfLoader',
    scale: Vector3(0.5, 0.5, 0.5),
  },
  {
    url: '/3d/rachid/Modern bed.gltf',
    img: '/3d/rachid/Modern bed.png',
    type: 'mp.gltfLoader',
    scale: Vector3(0.5, 0.5, 0.5),
  },
  {
    url: '/3d/rachid/sofa ombine .gltf',
    img: '/3d/rachid/sofa ombine .png',
    type: 'mp.gltfLoader',
    scale: Vector3(0.5, 0.5, 0.5),
  },
  {
    url: '/3d/rachid/Vk arnchair .gltf',
    img: '/3d/rachid/Vk arnchair .png',
    type: 'mp.gltfLoader',
    scale: Vector3(0.5, 0.5, 0.5),
  },
  {
    url: '/3d/rachid/White 3 seater sofa.gltf',
    img: '/3d/rachid/White 3 seater sofa.png',
    type: 'mp.gltfLoader',
    scale: Vector3(0.5, 0.5, 0.5),
  },
];
