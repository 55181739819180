import { popup } from "../../components/Popup/Popup";
import { queryClient } from "../../layouts/ReactQueryLayout";
import { Axios } from "./axios";

const createStaging = (data) => {
  return Axios.post(`/tours`, data).then(({ data }) => {
    queryClient.invalidateQueries("stagings");
    return data;
  });
};

const getAllStagings = () => {
  return Axios.get(`/tours`).then(({ data }) => {
    console.log({ stagings: data });
    return data;
  });
};

const getStaging = (id) => {
  return Axios.get(`/tours/${id}`).then(({ data }) => {
    console.log({ capture: data });
    return data;
  });
};

const updateStaging = (id, data) => {
  return Axios.put(`/tours/${id}`, data).then(({ data }) => {
    queryClient.invalidateQueries("stagings");
    return data;
  });
};

const deleteStaging = (data, id) => {
  return Axios.put(`/tours/${id}`, data).then(({ data }) => {
    queryClient.invalidateQueries("gestion");
    return data;
  });
};

export {
  createStaging,
  getAllStagings,
  getStaging,
  updateStaging,
  deleteStaging,
};
