import React, { useEffect, useRef, useState } from "react";
import "./FlexLinks.css";
import Icon from "../Icon/Icon";
import { items_3d } from "../../data";
import {
  createStaging,
  getStaging,
  updateStaging,
} from "../../utils/api/staging";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";

function FlexLinks({ children, setShowObjectMenu }) {
  const MATTERPORT_KEY = "nt5aaqqp900pq3hh388uc3yra";
  const [modelId, setModelId] = useState("j4RZx7ZGM6T");
  const ref = useRef();
  const refIframe = useRef();
  const [SDK, setSDK] = useState();
  const [sceneObject, setSceneObject] = useState();
  const [selected, setSelected] = useState();
  const [Control, setControl] = useState();
  const [Position, setPosition] = useState({ x: 0, y: 0, z: 0 });
  const [Rotation, setRotation] = useState({ x: 0, y: 0 });
  const url = "";
  const router = useNavigate();
  const [nodes, setNodes] = useState([]);

  function poseObserver(pose) {
    // use the current pose of the camera
    // console.log('pose', pose);
    const { position, rotation } = pose;
    setPosition({ x: position?.x, y: position?.y - 1, z: position.z });
    setRotation(rotation);
  }

  useEffect(() => {
    const showcase = document.getElementById("showcase");

    showcase.addEventListener("load", async function () {
      let sdk;
      try {
        sdk = await showcase.contentWindow.MP_SDK.connect(
          showcase,
          MATTERPORT_KEY,
          "3.6"
        );
        setSDK(sdk);
        console.log({ sdk });
      } catch (e) {
        console.error(e);
        return;
      }

      console.log(
        "%c  Hello Bundle SDK! ",
        "background: #333333; color: #00dd00"
      );

      const [sceneObject] = await sdk.Scene.createObjects(1);

      const lights = sceneObject.addNode();
      lights.addComponent("mp.lights");
      lights.start();
      setSceneObject(sceneObject);

      sdk.Camera.pose.subscribe(poseObserver);
    });

    return () => {};
  }, []);

  const save_items = () => {
    createStaging({
      matterportId: modelId,
      objects: nodes,
    }).then((data) => {
      navigator.clipboard.writeText(
        `${window.location.origin}/staging/${data?._id}`
      );
      toast.success("Link Copied Successfully");
    });
  };

  const print_3d_items = async (item, show = true, index = 0) => {
    const modelNode = sceneObject.addNode();
    const tableComponent = modelNode.addComponent(item?.type, {
      url: url + item?.url,
    });

    tableComponent.inputs.localScale = item?.scale;
    console.log({ Position, Rotation });
    const { x, y, z } = Position;
    const cosTheta = Math.cos(Rotation?.y * (Math.PI / 180));
    const sinTheta = Math.sin(Rotation?.y * (Math.PI / 180));

    modelNode.obj3D.position.set(x - sinTheta, y, z - cosTheta);
    // Create a scene node with a transform control component.
    const controlNode = sceneObject.addNode();
    const myControl = controlNode.addComponent("mp.transformControls", {
      eventsEnabled: true,
      userNavigationEnabled: false,
    });

    // Define an emit path to listen for mouse click emits
    const clickEmitPath = sceneObject.addEmitPath(
      tableComponent,
      "INTERACTION.CLICK"
    );

    // Define a click event spy
    const clickSpy = sceneObject.spyOnEvent({
      path: clickEmitPath,
      onEvent(payload) {
        setSelected(modelNode);
        setControl(myControl);
        myControl.inputs.selection = modelNode;
        myControl.inputs.size = 1;
        setShowObjectMenu(false);

        if (myControl.inputs.mode != "translate") {
          return;
        } else {
          myControl.inputs.mode = "translate";
        }
      },
    });

    setNodes([
      ...nodes,
      {
        id: modelNode.id,
        index,
        position: modelNode.obj3D.position,
        rotation: modelNode.obj3D.rotation,
        scale: modelNode.obj3D.scale,
      },
    ]);
    if (show) {
      modelNode.start();
      controlNode.start();
    } else {
      modelNode.stop();
      controlNode.stop();
    }
  };

  return (
    <>
      <div className="flex-link">
        <div className="input">
          <Icon name="search" />
          <input type="text" placeholder="Insérez le lien ici" ref={ref} />
        </div>
        <button
          onClick={() => {
            const url = ref.current.value; // Ensure this is the full URL
            const urlParams = new URLSearchParams(new URL(url).search);
            const modelId = urlParams.get("m");
            setModelId(modelId);
            console.log("Model ID:", modelId);
          }}
        >
          Visualiser
        </button>
      </div>

      <div className="iframe">
        {SDK &&
          children({
            print_3d_items,
            selected,
            Control,
            setSelected,
            nodes,
            setNodes,
            save_items,
          })}
        <iframe
          id="showcase"
          ref={refIframe}
          src={`/showcase-bundle/showcase.html?&brand=0&mls=2&mt=0&search=0&kb=0&play=1&qs=1&applicationKey=${MATTERPORT_KEY}&m=${modelId}`}
          frameBorder="0"
          allow="xr-spatial-tracking"
          allowFullScreen
        ></iframe>
      </div>
    </>
  );
}

export default FlexLinks;
