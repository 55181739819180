import EtatDesLieux from '../../screens/EtatDesLieux/EtatDesLieux';
import AppLayout from '../AppLayout';

const etatLieuxRouter = {
  path: '/etatLieux',
  element: (
    <AppLayout>
      <EtatDesLieux />
    </AppLayout>
  ),
};

export { etatLieuxRouter };
